import Logo from "./logo";


const Header = () => {

    return (
        <>
            <div className={"header"}>
                <Logo/>
            </div>
            <div className="greenbar">

            </div>
        </>
    );
}

export default Header;