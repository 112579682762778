import {IoIosCall} from 'react-icons/io'
import {GoPackage} from 'react-icons/go'
import {MdEmail} from 'react-icons/md'
const Contactbar = () => {

    return(
        <div className="contactbar">
            <div className="kontakt-header">
                <ul>
                    <li><IoIosCall color={"#70BE44"} size={"1.2rem"} /> <span>02325/9760700</span> </li>
                    <li><GoPackage color={"#70BE44"} size={"1.2rem"} /> <span>3-4 Werktage</span> </li>
                    <li><MdEmail color={"#70BE44"} size={"1.2rem"}/><span>info@vairpack.de</span></li>

                </ul>
            </div>


        </div>
    );
}


export default Contactbar;
