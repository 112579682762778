import Contactbar from "../component/contactbar";
import Header from "../component/header";
import headerImage from "../image/header.jpg"
import Dreieck from "../component/dreieck";
import Vorstellung from "../component/vorstellung";

const Home = () => {
    return (
        <div className={"home"}>
            <Header headerImage={headerImage}/>
            <Dreieck/>
            <Vorstellung/>
        </div>
    )
}

export default Home;
