import FormDankeAnfrage from "./formDankeAnfrage";
import {useEffect, useRef, useState} from "react";

const Kontaktfeld = () => {

    const formDanke = useRef();
    const formEmpfehlung = useRef();
    const [send,setSend] = useState(false);

    useEffect(() => {
        if (send) {


        } else {
            formEmpfehlung.current.style.opacity = '0'
            formEmpfehlung.current.style.display = 'none'
        }

        if (send ) {
            formDanke.current.style.display = 'flex'
        } else {
        }

        if (send){
            formEmpfehlung.current.style.opacity = '0'
            formEmpfehlung.current.style.display = 'none'

            formDanke.current.style.display = 'flex'
            formDanke.current.style.opacity = '1'

        }else{
            formDanke.current.style.display = 'none'
            formDanke.current.style.opacity = '0'

            formEmpfehlung.current.style.display = 'flex'
            formEmpfehlung.current.style.opacity = '1'

        }


    })


    return (
        <div className="kontaktfeld" id={'kontaktfeld'}>
            <h1>Haben Sie noch Fragen ?</h1>
            <h3>Anfragen über das Kontaktformular werden so schnell wie uns möglich beantwortet.</h3>

            <iframe width={0} height={0} name={'hiddenFrame'} id={'hiddenFrame'} style={{display: 'none'}} />

            <form method={"post"} action="./php/tipp.php" target={'hiddenFrame'} onSubmit={() => setSend(true)}>
                <div ref={formEmpfehlung} className="felder">
                    <div className="spalte1">
                        <div className="feld">
                            <label htmlFor={'vorname'}>Vorname</label>
                            <input type={'text'} id={'vorname'} name={'vorname'} placeholder={'Max'}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'nachname'}>Nachname</label>
                            <input type={'text'} id={'nachname'} name={"nachname"} placeholder={'Musterman'}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'email'}>E-Mail</label>
                            <input type={'text'} id={'email'} name={"email"} placeholder={'max.musterman@muster.de'}/>
                        </div>
                    </div>
                    <div className="spalte2">
                        <div className="feld">
                            <label htmlFor={'telefon'}>Telefon</label>
                            <input type={'text'} id={'telefon'} name={'telefon'} placeholder={'01623451829'}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'nachricht'}>Nachricht</label>
                            <textarea id={'nachricht'} name={"nachricht"} placeholder={'Nachricht ...'}/>
                        </div>

                    </div>
                    <button  type={'submit'}>Senden</button>

                </div>
                <FormDankeAnfrage reff={formDanke}/>


            </form>
        </div>
    )
}
export default Kontaktfeld;
