import Home from "./page/home";
import Footer from "./component/footer";
import Contactbar from "./component/contactbar";
import React from "react";
import Kategorievorschau from "./component/kategorievorschau";
import Kontaktfeld from "./component/kontaktfeld";
import DatenschutzBanner from "./component/datenschutzBanner";

function App() {
    return (
        <div className="App">
            <Contactbar/>
            <Home/>
            <Kategorievorschau />
            <Kontaktfeld />
            <Footer />
            <DatenschutzBanner/>
        </div>
    );
}

export default App;
