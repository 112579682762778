import eisbecher from "../image/produkte/becher/EIS_Becher.png";

const Produktkarte = ({bild,name}) => {

    return(
        <div className="produkt">
            <div className="produktbild" style={{    background: `url(${bild})  center no-repeat `,
                backgroundSize: "contain"}}/>
            <p className="produktname">{name}</p>
        </div>
    );
}

export default Produktkarte;