import logo from '../image/logo.png'
import {Link} from 'react-router-dom'

const Logo = () => {


    return (
        <div className={"logo"}>
            <img src={logo} alt="vairpack-logo"/>
        </div>
    );
}


export default Logo;