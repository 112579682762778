import {Link} from "react-router-dom";
import {useState} from "react";

const DatenschutzBanner = () => {

    const [akzeptiert,setAkzeptiert] = useState(false);

    return (
        <div className="datenschutzbanner" style={akzeptiert ? {display:'none'} :{display:'flex'}}>
            <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir
                Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere
                Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung.</p>
            <button onClick={() => setAkzeptiert(true)}>Akzeptieren</button>
        </div>
    );
}
export default DatenschutzBanner;