import Wagen from '../image/wagen.png';
import enegie from '../image/grune-energie.png';
import losung from '../image/losung.png';

const Vorstellung = () => {

    return (
        <div className="vorstellung">
            <div className="absatz">
                <img src={Wagen} alt="Kohle-Wage"/>
                <p>Dem Verpackungslieferanten aus Nordrheinwestfalen.</p>
            </div>
            <div className="absatz">
                <img src={enegie} alt="Kohle-Wage"/>
                <p>Durch unsere erfahrenen Mitarbeiter und Leistungsfähigen
                    Partner bietet Ihnen <span style={{color: "#78B73A"}}>Vairpack</span> Lösungen nach Maß.</p>
            </div>
            <div className="absatz">
                <img src={losung} alt="Kohle-Wage"/>
                <p>Ihre Wünsche stehen dabei im Mittelpunkt.
                    Unser motiviertes Team berät Sie gern und kompetent.
                </p>
            </div>
            <div className="absatz">
                <p>Wir freuen uns auf Ihren Anruf. Ihr <span style={{color: "#78B73A"}}>Vairpack</span> Team</p>
                <p style={styleAbsatz}></p>

            </div>


        </div>
    );
}

export default Vorstellung;

const styleAbsatz = {
    marginTop: "3rem"
}