
const FormDankeAnfrage = ({reff}) => {


    return (
        <div className="formPersoen" style={{border: 'none'}} ref={reff}>
            <div >
                <h1 style={{color: '#78B73A'}}>Danke für deine Anfrage.</h1>
                <p style={{color: '#78B73A'}}>Wir werden uns so schnell wie möglich bei Ihnen zurück melden.</p>
            </div>

        </div>
    );
}

export default FormDankeAnfrage;
