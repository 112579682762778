import eisbecher from "../image/produkte/becher/EIS_Becher.png";
import eislöffel from "../image/produkte/besteck/EISLöffel_Holt.png";
import holzgabel from "../image/produkte/besteck/HOLZ_Gabel.png";
import naturesse from "../image/produkte/besteck/Naturesse.png";
import pseloeffel from "../image/produkte/besteck/PSE_löffel.png";

//Foodbox
import box1 from "../image/produkte/Foodbox/BIO_BOX_EINFACH.png";
import box5 from "../image/produkte/Foodbox/Freisteller_SchaleToGo.png";
import box7 from "../image/produkte/Foodbox/HOCHTIEF_SCHALE.png";
import box8 from "../image/produkte/Foodbox/Papp_schachtel_dünn.png";
import box9 from "../image/produkte/Foodbox/Schale_weiß_drei.png";
import box10 from "../image/produkte/Foodbox/Take_away_Box_dünn.png";

//geschier
import g1 from "../image/produkte/geschier/FlackTeller_Weiß.png";
import g2 from "../image/produkte/geschier/Freisteller_Schale_eckig.png";
import g5 from "../image/produkte/geschier/Pappe_Schüssel_Dick.png";
import g6 from "../image/produkte/geschier/Schale_dick_hoch.png";
import g8 from "../image/produkte/geschier/ZweiFachSchale.png";

//neu
import n1 from "../image/produkte/new/Holzmesser.gif";
import n2 from "../image/produkte/new/Holzgabel.gif";
import n3 from "../image/produkte/new/Pappiertüte_Braun.gif";
import n4 from "../image/produkte/new/Pappschale.gif";
import n5 from "../image/produkte/new/Snackholzgabel_mit_Messer.gif";
import n6 from "../image/produkte/new/Snackschale_Pappe.gif";
import n7 from "../image/produkte/new/Strohalm_mit_Loeffel.gif";







import Produktkarte from "./produktkarte";


const Kategorievorschau = () => {


    return (

        <div className="kategorievorschau">
            <div className="vorschau">
                <div className="aufbau">
                    <h1>Produkt Übersicht</h1>
                    <h3>Die Webseite ist aktuell noch im Aufbau.</h3>
                    <p>Es sind mehr Produkte vorhanden, als die webseite aktuell zeigt. Für weitere Wünsche und Anfragen
                        stehen wir Ihnen jederzeit zur Verfügung. </p>
                </div>

                <div className="produktreihe">
                    <Produktkarte bild={eisbecher} name={"Suppenbecher (Pappe)"}/>
                    <Produktkarte bild={eislöffel} name={"Holzspaten"}/>
                    <Produktkarte bild={holzgabel} name={"Holzgabel"}/>
                    <Produktkarte bild={naturesse} name={"Messer aus CPLA"}/>
                    <Produktkarte bild={pseloeffel} name={"Löffel aus CPLA"}/>

                    <Produktkarte bild={box1} name={"Lunch Box(Bagasse)"}/>
                    <Produktkarte bild={box5} name={"Dönerbox (Pappe)"}/>

                    <Produktkarte bild={box7} name={"Hamburger Box (Bagasse)"}/>
                    <Produktkarte bild={box8} name={"Snackbox (Pappe)"}/>
                    <Produktkarte bild={box9} name={"Menü Box (Bagasse)"}/>
                    <Produktkarte bild={box10} name={"Hamburger Box (Pappe)"}/>

                    <Produktkarte bild={g1} name={"Imbissteller (Bagasse)"}/>
                    <Produktkarte bild={g2} name={"Palmenteller"}/>
                    <Produktkarte bild={g5} name={"Bowl (Pappe)"}/>
                    <Produktkarte bild={g6} name={"Salatschale (Bagasse)"}/>
                    <Produktkarte bild={g8} name={"Menüteller (Bagsse)"}/>

                    <Produktkarte bild={n1} name={"Holzmesser"}/>
                    <Produktkarte bild={n2} name={"Holzgabel"}/>
                    <Produktkarte bild={n3} name={"Pappiertüte"}/>
                    <Produktkarte bild={n4} name={"Pappschale"}/>
                    <Produktkarte bild={n5} name={"Snackholzgabel mit Messer"}/>
                    <Produktkarte bild={n6} name={"Snackschale (Pappe)"}/>
                    <Produktkarte bild={n7} name={"Strohalm mit Loeffel"}/>

                </div>

            </div>


        </div>
    );
}
export default Kategorievorschau;
